.container {
  
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  height: 80vh;
}

form {
  
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 200px;
  width: 100%;
}

.input-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}

button {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.button-container {
  /* Stilurile containerului butonului aici */
  text-align: center;
}

.card {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 200px;
  width: 100%;
}