body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


ul.menu_list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #185792;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #111111;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}

.row {margin: 0 -5px;}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 25%;
  padding: 0 10px;
}

.wrapper {
  padding-top: 0.5%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: 100px 200px;
}

.grid-container {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  background-color: #96caf5;
  padding: 0.5%;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(28, 201, 245, 0.8);
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 3%;
  padding: 2%;
  font-size: 14px;
  text-align: center;
}

.download_button {
  background-color: DodgerBlue;
  margin-right: 80px;
  border: none;
  color: white;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 20px;
}

.download_button:hover {
  background-color: RoyalBlue;
}

.delete_button {
  background-color: rgb(247, 0, 0);
  margin-right: 0px;
  border: none;
  color: white;
  padding: 7px 20px;
  cursor: pointer;
  font-size: 12px;
}

.delete_button:hover {
  background-color: rgb(100, 2, 2);
}

.ExcelButton {
  margin-right: 10px;
  padding: 10px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.Chart {
  width: 50%;
}

@media screen and (max-width: 960px) {
  .grid-container {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2%;
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: #96caf5;
    padding: 0.5%;
  }

  .Chart {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .grid-container {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2%;
    display: grid;
    grid-template-columns: auto auto;
    background-color: #96caf5;
    padding: 0.5%;
  }
  .ExcelButton {
    margin-right: 0px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Chart {
    width: 90%;
  }
  
  
  
}